import {api} from '../api';

const header = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
// Register
const register = (data: any) => {
  return api.post('admin/register', data);
};
// Update
const updateAdmin = (token: string, id: any, data: any) => {
  return api.patch(`admin/update/${id}`, data, header(token));
};

// Login
const login = (data: any) => {
  return api.post('admin/login', data);
};
const supportLogin = (data: any) => {
  return api.post('support/login', data);
};

export const AuthServices = {
  register,
  login,
  supportLogin,
  updateAdmin,
};
