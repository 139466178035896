/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {AssignRole, PencilSm} from '../../../../assets/images';
import Input from '../../../../components/dashboard/profile/input';
import {CloseCircle} from 'iconsax-react';
import {Loader} from '../../../../assets/svg';
import Button from '../../../../components/ui/Button';
import Select from '../../../../components/dashboard/profile/select';

interface IModal {
  handleEditAdmin: (id: any, adminRole: string) => void;
  isActionLoading: boolean;
  setShowView: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAddNewAdmin {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
}
const EditAdmin = ({
  setShowView,
  handleEditAdmin: handleAddNewAdmin,
  isActionLoading,
}: IModal) => {
  //   console.log(vendor);

  const admin = JSON.parse(localStorage.getItem('#idAdmin') || '{}');
  const [formData, setFormData] = useState<IAddNewAdmin>({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
  });
  useEffect(() => {
    setFormData((p: any) => ({
      ...p,
      email: admin.email,
      firstName: admin.firstName,
      lastName: admin.lastName,
      phone: admin.phone,
      role: admin.role,
    }));
  }, [admin.email, admin.firstName, admin.lastName, admin.phone, admin.role]);
  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const roleOption = [
    {name: 'Vendor Management'},
    {name: 'Customer  Management'},
    {name: 'Operator'},
  ];
  return (
    <div>
      <div
        className='bg-[#002668] w-screen h-screen z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fixed opacity-30'
        onClick={() => setShowView(true)}
      />
      <div className='z-50 absolute lg:top-[20%] top-[10%] left-[35%] w-full h-[80%] overflow-auto'>
        {/* Content */}
        <div className='bg-white p-8 relative rounded-xl w-[50%] lg:w-[50%] xl:w-[40%]'>
          <div
            className='absolute top-4 right-4 cursor-pointer'
            onClick={() => setShowView(false)}>
            <CloseCircle size={22} variant='Bold' color='red' />
          </div>
          <div className='w-24 h-24 my-6'>
            <img
              src={AssignRole}
              alt='assignRole'
              className='w-full h-full object-cover rounded-full'
            />
          </div>
          <p className='text-lg text-primary font-poppinsSemibold py-4'>
            Edit Admin
          </p>

          <div className='grid lg:grid-cols-2 grid-cols-1 gap-6 w-full pb-4'>
            <div className='w-full'>
              <Input
                label='First Name'
                icon={PencilSm}
                name='firstName'
                handleChange={handleInputChange}
                valueField={formData.firstName || ''}
                placeholder='First Name'
                required
              />
            </div>
            <div className='w-full'>
              <Input
                label='Last Name'
                icon={PencilSm}
                name='lastName'
                handleChange={handleInputChange}
                valueField={formData.lastName || ''}
                placeholder='Last Name'
                required
              />
            </div>
          </div>
          <div className='grid lg:grid-cols-2 grid-cols-1 gap-6 w-full pb-4'>
            <div className='w-full'>
              <Input
                label='Email'
                icon={PencilSm}
                name='email'
                handleChange={handleInputChange}
                valueField={formData.email || ''}
                placeholder='Enter Email'
                required
              />
            </div>
            <div className='w-full'>
              <Input
                label='Phone Number'
                icon={PencilSm}
                name='phone'
                handleChange={handleInputChange}
                valueField={formData.phone || ''}
                placeholder='Enter Phone'
                required
              />
            </div>
          </div>
          <div className='grid lg:grid-cols-2 grid-cols-1 gap-6 w-full pb-4'>
            <div className='w-full'>
              <Select
                label='Role'
                options={roleOption}
                name='role'
                handleChange={handleInputChange}
                valueField={formData.role || ''}
                required
              />
            </div>
          </div>
          <div className='w-full py-2'>
            {isActionLoading ? (
              <Button
                label={<Loader color='white' />}
                width='full'
                bgColor='#825A3F'
                textCOlor='white'
              />
            ) : (
              <Button
                label={'Edit User Account'}
                width='full'
                bgColor='#825A3F'
                textCOlor='white'
                onClick={() => handleAddNewAdmin(admin?.id, formData.role)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
